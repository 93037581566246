import {environment} from './../../../environments/environment.prod';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-account-toolbar',
	templateUrl: './account-toolbar.component.html',
	styleUrls: ['account-toolbar.component.scss']
})
export class AccountToolbarComponent implements OnInit {
	@Input()
	title: string;
	@Input()
	icon: string;
	@Input()
	src: string;
	@Output() back = new EventEmitter<void>();
	environment = environment;

	constructor() {}

	ngOnInit() {}

	goBack() {
		this.back.emit();
	}
}
