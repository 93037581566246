import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const foodloungeCustomerGroup = 'foodlounge';
export const customerGroup = foodloungeCustomerGroup;

const supportEmail = {
	foodlounge: 'foodlounge@smoothr.de'
};
const firebaseConfig = {
	foodlounge: {
		apiKey: 'AIzaSyDzopA0HXMwlMKLKBu93uzX5y_1EgseEec',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:369149614f90894ee0fe06',
		measurementId: 'G-9XL5NEV1HL'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAUJ0yCbuGUs9lDKehj-VGlD0oeU3XO__A',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	gaId: '',
	...apiEnvironment
};
