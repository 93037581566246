import {Injectable} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {environment} from '../../../environments/environment';
import Order from '../../models/Order';
import ArticleGroup from '../../models/ArticleGroup';
import {OrderUtils} from '../../utils/order-utils';
import {PaymentMethod} from '../../enums/PaymentMethod';
import Payment from '../../models/Payment';
import Article from '../../models/Article';
import {OrderType} from '../../enums/OrderType';
import {PreorderType} from '../../enums/PreorderType';
import {getPrice, loadGoogleTag} from '../../utils/utils';

declare const gtag: any;

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	static orderHasProduct = false;

	constructor(private analytics: AngularFireAnalytics) {}

	logVersion() {
		this.logEvent('version', {
			customer: environment.customerGroup,
			version: environment.version
		});
	}

	addToCart(order: Order, articleGroup: ArticleGroup) {
		this.logEvent('add_to_cart', {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					)
				};
			})
		});
	}

	beginCheckout(order: Order) {
		this.logEvent('begin_checkout', {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					)
				};
			}),
			coupon:
				order.promoCode && order.promoCode.code
					? order.promoCode.code
					: undefined
		});
	}

	emailVerificationPageOpened() {
		this.logEvent('email_verification_page_opened');
	}

	orderVerified(order: Order) {
		this.logEvent('order_verified', {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					)
				};
			}),
			coupon:
				order.promoCode && order.promoCode.code
					? order.promoCode.code
					: undefined
		});
		this.checkoutProgress(order, null, 2);
	}

	checkoutProgress(
		order: Order,
		paymentMethod: PaymentMethod,
		checkoutStep: number
	) {
		this.logEvent('checkout_progress', {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					)
				};
			}),
			checkout_option: paymentMethod,
			coupon:
				order.promoCode && order.promoCode.code
					? order.promoCode.code
					: undefined
		});
	}

	paymentLoadingFailed(order: Order, paymentMethod: PaymentMethod) {
		this.logEvent('payment_loading_failed', {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					)
				};
			}),
			checkout_option: paymentMethod,
			coupon: order.promoCode?.code ?? undefined
		});
	}

	paymentFailed(order: Order, payment: Payment, error: any) {
		this.logEvent('payment_failed', {
			order: order._id,
			payment: payment._id,
			error
		});
	}

	paymentLoaded(order: Order, paymentMethod: PaymentMethod) {
		this.logEvent('payment_loaded', {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					)
				};
			}),
			checkout_option: paymentMethod,
			coupon:
				order.promoCode && order.promoCode.code
					? order.promoCode.code
					: undefined
		});
		this.checkoutProgress(order, paymentMethod, 3);
	}

	purchase(order: Order, payment: Payment) {
		this.logEvent('purchase', {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					)
				};
			}),
			transaction_id: payment._id,
			shipping: order.preorder?.deliveryFee ?? undefined,
			coupon: order.promoCode?.code ?? undefined
		});
	}

	search(searchTerm: string) {
		this.logEvent('search', {
			search_term: searchTerm
		});
	}

	selectContentProduct(
		article: Article,
		orderType: OrderType,
		preorderType: PreorderType
	) {
		this.logEvent('select_content', {
			content_type: 'product',
			items: {
				id: article.masterId,
				name: article.name.de,
				quantity: 1,
				price: getPrice(article, orderType, preorderType, null)
			}
		});
	}

	visitMenuPage() {
		this.logEvent('visit_menu_page');
	}

	openOrderContent() {
		this.logEvent('visit_order_content');
	}

	orderHasProducts() {
		if (AnalyticsService.orderHasProduct) {
			return;
		}
		AnalyticsService.orderHasProduct = true;
		this.logEvent('order_has_products');
	}

	customerBeginsCheckout() {
		this.logEvent('checkout_modal_opened');
	}

	paymentModalOpened() {
		this.logEvent('payment_modal_opened');
	}

	paymentSuccess() {
		this.logEvent('payment_success');
	}

	beginArticleEdit(article: Article) {
		const eventParams = {
			article: article.name.de,
			id: article._id
		};
		this.logEvent('begin_article_edit', eventParams);
	}

	stepArticleEdit(article: Article, step: number) {
		const eventParams = {
			article: article.name.de,
			step,
			id: article._id
		};
		this.logEvent('step_article_edit', eventParams);
	}

	finishArticleEdit(article: Article, step: number) {
		const eventParams = {
			article: article.name.de,
			step,
			id: article._id
		};
		this.logEvent('finish_article_edit', eventParams);
	}

	cancelArticleEdit(article: Article, step: number) {
		this.logEvent('cancel_article_edit', {
			article: article.name.de,
			step,
			id: article._id
		});
	}

	async setAnalyticsCollectionEnabled(enabled: boolean) {
		console.log(`setAnalyticsCollectionEnabled(enabled: ${enabled})`);
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
		if (environment.gaId) {
			if (enabled) {
				window['ga-disable-' + environment.gaId] = false;
				loadGoogleTag(environment.gaId);
			} else {
				window['ga-disable-' + environment.gaId] = true;
			}
		}
	}

	private logEvent(eventName: string, eventParams: any = {}) {
		gtag('event', eventName, eventParams);
		this.analytics.logEvent(eventName, eventParams).then(() => {
			if (environment.production) {
				return;
			}
			console.log(eventName, eventParams);
		});
	}
}
