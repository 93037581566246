import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import validator from 'validator';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {TranslateService} from '@ngx-translate/core';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';

@Component({
	selector: 'app-sign-in-order',
	templateUrl: './sign-in-order.page.html',
	styleUrls: ['sign-in-order.page.scss']
})
export class SignInOrderPage extends RepositoryDirective implements OnInit {
	static url = 'sign-in-order';
	isValid = false;
	loading = false;
	showPassword = false;

	constructor(
		private translate: TranslateService,
		private router: Router,
		public repository: RepositoryService,
		private auth: AngularFireAuth,
		private snackbarCtrl: MatSnackBar
	) {
		super(repository);
	}

	// tslint:disable-next-line:variable-name
	private _email = '';

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
		this.validate();
	}

	// tslint:disable-next-line:variable-name
	private _password = '';

	get password(): string {
		return this._password;
	}

	set password(value: string) {
		this._password = value.replace(' ', '');
		this.validate();
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(SignInOrderPage.url);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	validate() {
		this.isValid =
			this.password &&
			this.password.length > 5 &&
			validator.isEmail(this.email);
	}

	async signIn() {
		this.validate();
		if (!this.isValid) {
			this.snackbarCtrl.open(
				this.translate.instant('errors.auth_error'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		this.loading = true;
		try {
			const response = await Api.authLogin({
				email: this.email,
				password: this.password
			});
			this.repository.authLogin.emit(response.data);
		} catch (e) {
			this.repository.authLogin.emit(null);
			console.error(e);
		}
		this.loading = false;
	}

	reset() {
		this.repository.authLogin.emit(null);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
