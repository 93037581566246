import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const foodloungeCustomerGroup = 'foodlounge';
export const customerGroup = foodloungeCustomerGroup;

const supportEmail = {
	foodlounge: 'foodlounge@smoothr.de'
};
const firebaseConfig = {
	foodlounge: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:85285c55b799b0f5cbfff0',
		measurementId: 'G-BS1PP1R32C'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBxctZyYOPXBxP8pv-IsX0F3y16TB3nCFU',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	gaId: '',
	...apiEnvironment
};
