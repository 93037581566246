import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import {AppComponent} from '../../app.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';
import Table from '../../../smoothr-web-app-core/models/Table';
import {Api} from '../../../smoothr-web-app-core/api/api';

@Component({
	selector: 'app-scan-qr',
	templateUrl: './scan-qr.component.html',
	styleUrls: ['scan-qr.component.scss']
})
export class ScanQrModal {
	scanning = false;
	loading = false;
	venue: Venue;

	constructor(
		private snackBarCtrl: MatSnackBar,
		private router: Router,
		private modalCtrl: ModalController
	) {}

	/**
	 * returns table number
	 */
	static async show(modalCtrl: ModalController, venue: Venue): Promise<Table> {
		const resultScan = await modalCtrl.create({
			component: ScanQrModal,
			cssClass: AppComponent.largeScreen
				? 'item-modal large-modal'
				: 'item-modal',
			componentProps: {
				venue
			}
		});
		await resultScan.present();
		const res = (await resultScan.onDidDismiss()).data;
		await sleep(100);
		return res;
	}

	close() {
		this.modalCtrl.dismiss();
	}

	startScanning() {
		this.scanning = true;
		this.scanSuccessHandler('https://dev-cotidiano.smoothr.de/scan-qr/table/1');
	}

	stopScanning() {
		this.scanning = false;
	}

	scanSuccessHandler($event: string) {
		this.loading = true;
		this.snackBarCtrl.open(
			'Wir suchen deine Bestellung. Einen Moment...',
			null,
			{
				duration: 2000
			}
		);
		this.stopScanning();
		try {
			this.takeOverOrder(
				$event.split('https://dev-cotidiano.smoothr.de/scan-qr/table/')[1]
			);
		} catch (e) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
		}
	}

	async takeOverOrder(tableNumber: string) {
		if (tableNumber == null) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
			return;
		}
		try {
			// const table = (await Api.getTable(this.venue._id, tableNumber)).data;
			// this.modalCtrl.dismiss(table);
		} catch (e) {
			console.log(e);
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Fehler beim Abrufen des Tisches: ' + e, null, {
				duration: 2000
			});
			this.modalCtrl.dismiss();
		}
		this.loading = false;
	}
}
