import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {
	AllowGpsModalComponent,
	AllowGpsModalResult
} from '../allow-gps-modal/allow-gps-modal.component';
import {MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Address from '../../../smoothr-web-app-core/models/Address';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

export class SelectVenueModalResult {
	constructor(
		public venue: Venue,
		public address: Address,
		public preorderType: PreorderType
	) {}
}

@Component({
	selector: 'app-select-venue-modal',
	templateUrl: './select-venue-modal.component.html',
	styleUrls: ['select-venue-modal.component.scss']
})
export class SelectVenueModalComponent implements OnInit {
	@ViewChild('mapContainer', {static: true})
	mapElement: ElementRef;
	map: Map;
	venues: Venue[] = [];
	loading = true;
	allowGps: boolean;

	selectedVenue: Venue = null;
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{visibility: 'off'}]
			}
		]
	};
	clusterer: MarkerClusterer = null;
	error: string;

	constructor(
		private repository: RepositoryService,
		private snackBarCtrl: MatSnackBar,
		private modalCtrl: ModalController
	) {}

	private _showList = true;

	get showList() {
		return this._showList;
	}

	set showList(value: boolean) {
		this._showList = value;
		this.setupMap(this.selectedVenue);
	}

	static show(modalCtrl: ModalController): Promise<SelectVenueModalResult> {
		return new Promise<SelectVenueModalResult>(async resolve => {
			const modal = await modalCtrl.create({
				component: SelectVenueModalComponent,
				cssClass: 'select-venue-modal auto-height',
				componentProps: {
					allowGps: false
				}
			});
			await modal.present();
			const result: SelectVenueModalResult = (await modal.onDidDismiss()).data;
			resolve(result);
		});
	}

	ngOnInit() {
		this.loadVenues();
	}

	async loadVenues() {
		this.loading = true;
		let address: Address;
		if (this.allowGps) {
			try {
				address = await MapsUtils.getUserGeocode();
			} catch (e) {
				// position could not be retrieved
			}
		}
		try {
			if (address) {
				this.venues = await this.repository.getVenuesByAddress(address);
			} else {
				this.venues = await this.repository.getAllVenues();
			}
		} catch (e) {
			this.snackBarCtrl.open(
				'Error while loading venues please try it later again'
			);
			await this.modalCtrl.dismiss();
		}
		if (this.venues.length <= 0) {
			this.loading = false;
			this.error = 'Keine Restaurants in deiner Nähe';
			return;
		}
		this.selectedVenue = this.venues[0];
		if (!this.showList) {
			await this.setupMap(this.selectedVenue);
		}
		this.loading = false;
	}

	selectVenue(venue: Venue, preorderType: PreorderType) {
		const address = new Address();
		address.street = venue.street;
		address.city = venue.city.de;
		address.number = venue.number;
		address.postalCode = venue.postalCode;
		address.country = venue.country;
		address.lat = venue.location.coordinates[1];
		address.lng = venue.location.coordinates[0];
		this.modalCtrl.dismiss(
			new SelectVenueModalResult(venue, address, preorderType)
		);
	}

	async setupMap(selectedVenue: Venue) {
		const previousLoading = this.loading;
		if (!previousLoading) {
			this.loading = true;
		}
		if (!this.map) {
			this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		}
		this.selectedVenue = selectedVenue;
		this.clusterer = MapsUtils.addVenuesToMap(
			this.clusterer,
			selectedVenue,
			this.venues,
			this.map,
			venue => this.setupMap(venue)
		);
		if (!previousLoading) {
			this.loading = false;
		}
	}
}
