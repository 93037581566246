import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {
	getPrice,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-kl-overlay-option-item',
	templateUrl: './kl-overlay-option-item.component.html',
	styleUrls: ['kl-overlay-option-item.component.scss']
})
export class KlOverlayOptionItemComponent implements OnInit {
	@Input() article: Article;
	@Input() preorderType: PreorderType;
	@Input() selected: boolean;
	@Input() currency: string;

	@Output() selection = new EventEmitter<Article>();

	numberToCurrency = numberToCurrency;

	constructor() {}

	get price(): number {
		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	ngOnInit() {}

	selectArticle(article: Article) {
		this.selection.emit(article);
	}
}
