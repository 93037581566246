import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import Order from '../../../smoothr-web-app-core/models/Order';
import {MenuPage} from '../menu/menu.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AnalyticsService} from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import {OrderUtils} from 'src/smoothr-web-app-core/utils/order-utils';
import {numberToCurrency, sleep} from 'src/smoothr-web-app-core/utils/utils';
import {OrderListAction} from '../../enums/OrderListAction';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {
	PaymentModalComponent,
	PaymentModalResult
} from '../../../smoothr-web-app-core/components/payment-modal/payment-modal.component';
// import {PaymentSelectionSyncService} from '../../../smoothr-web-app-core/services/PaymentSelectionSyncService';
import {Subscription} from 'rxjs/internal/Subscription';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PaymentSuccessPage} from '../payment-success/payment-success.page';

@Component({
	selector: 'app-multiple-order',
	templateUrl: './multiple-order.page.html',
	styleUrls: ['multiple-order.page.scss']
})
export class MultipleOrderPage
	extends RepositoryDirective
	implements OnInit, OnDestroy
{
	static url = 'multiple-orders';
	venue: Venue;
	notPaid: Order[] = [];
	paid: Order[] = [];
	selectedOrders = new Set<string>();
	selectedOrdersByOthers = new Set<string>();
	loading = false;
	currentTable: string;

	isValid = false;
	totalPrice = 0;
	totalNotPaid = 0;

	promoError: string;
	tip = 0;
	numberToCurrency = numberToCurrency;
	onTableUpdateSubscription: Subscription;
	onConnectSubscription: Subscription;

	constructor(
		public repository: RepositoryService,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private translate: TranslateService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private analytics: AnalyticsService,
		// private psss: PaymentSelectionSyncService
	) {
		super(repository);
	}

	get hasArticles(): boolean {
		return this.selectedOrders.size > 0;
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MultipleOrderPage.url);
	}

	ionViewWillEnter() {
		this.loadOrders();
	}

	onCustomer() {
		super.onCustomer();
		this.loadOrders();
	}

	async loadOrders() {
		if (this.order.table) {
			this.currentTable = this.order.table;
		}
		this.loading = true;
		try {
			const venue = await this.repository.getVenue(this.order.venue);
			const articleList = [];
			for (const category of venue.articleCategories) {
				for (const article of category.articles) {
					articleList.push(article);
				}
			}
			// const res = (
			// 	await Api.getOrdersByTable(this.order.venue, this.order.table)
			// ).data.map(it => this.reloadOrder(it, articleList));
			// this.paid = res.filter(it => it.isPayed);
			// this.notPaid = res.filter(it => !it.isPayed);
			// this.totalNotPaid = this.notPaid.reduce((prev, curr) => {
			// 	return prev + OrderUtils.orderTotalPrice(curr, true, true);
			// }, 0);
			// this.psss.join(this.customer.uid, this.order.table);
		} catch (err) {
			console.log(err);
			await sleep(1000);
			this.loading = false;
			this.cdr.detectChanges();
		}
		this.loading = false;
	}

	reloadOrder(order: Order, articleList: Article[]) {
		order.orderedArticles = order.orderedArticles.map(oa => {
			oa.article.assets = articleList.find(
				article => oa.article._id === article._id
			)?.assets;
			return oa;
		});
		return order;
	}

	goBack() {
		MenuPage.navigate(this.router);
	}

	addArticle() {
		MenuPage.navigate(this.router);
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.openOrderContent();
		this.initSocket();
	}

	initSocket() {
		// this.onConnectSubscription = this.psss.onConnect().subscribe(() => {
		// 	this.selectedOrders = new Set();
		// 	this.selectedOrdersByOthers = new Set();
		// 	this.psss.join(this.customer.uid, this.currentTable);
		// });
		// this.onTableUpdateSubscription = this.psss
		// 	.onTableUpdate()
		// 	.subscribe(event => {
		// 		const array = Array.from(event);
		// 		this.selectedOrders = new Set(
		// 			array
		// 				.filter(it => it.customer === this.customer.uid)
		// 				.map(it => it.order)
		// 		);
		// 		this.selectedOrdersByOthers = new Set(
		// 			array
		// 				.filter(it => it.customer !== this.customer.uid)
		// 				.map(it => it.order)
		// 		);
		// 		this.onSelectionChange();
		// 	});
	}

	ngOnDestroy() {
		this.onTableUpdateSubscription.unsubscribe();
		this.onConnectSubscription.unsubscribe();
		this.onTableUpdateSubscription = null;
		this.onConnectSubscription = null;
		// this.psss.disconnect();
		super.ngOnDestroy();
	}

	onInitFinish() {
		super.onInitFinish();
		// this.psss.connect();
	}

	validPrice() {
		return this.totalPrice > 0;
	}

	async onAction(id: string, event: OrderListAction) {
		switch (event) {
			case OrderListAction.addtopay:
				// this.psss.selectOrder(
				// 	this.customer.uid,
				// 	id,
				// 	this.notPaid.find(order => order._id === id).table
				// );
				break;
			case OrderListAction.removefrompay:
				// this.psss.unselect(
				// 	this.customer.uid,
				// 	id,
				// 	this.notPaid.find(order => order._id === id).table
				// );
				break;
		}
	}

	onSelectionChange() {
		const selectedOrders = this.notPaid.filter(o =>
			this.selectedOrders.has(o._id)
		);
		this.totalPrice = selectedOrders.reduce((prev, curr) => {
			return prev + OrderUtils.orderTotalPrice(curr, true, true);
		}, 0);
		this.cdr.detectChanges();
	}

	formattedTotalPrice(): string {
		return numberToCurrency(this.totalPrice, this.venue?.currency);
	}

	formattedTotalNotPaid(): string {
		return numberToCurrency(this.totalNotPaid, this.venue?.currency);
	}

	async goToPayment() {
		this.loading = true;
		const ordersToPay = Array.from(this.selectedOrders).map(it =>
			this.notPaid.find(order => order._id === it)
		);
		// const res = await PaymentModalComponent.show(
		// 	this.modalCtrl,
		// 	this.venue,
		// 	ordersToPay,
		// 	this.customer
		// );
		// console.log(res);
		// switch (res.result) {
		// 	case PaymentModalResult.SUCCESS:
		// 		await PaymentSuccessPage.navigate(
		// 			this.router,
		// 			ordersToPay.map(it => it._id),
		// 			res.payment._id
		// 		);
		// 		break;
		// 	case PaymentModalResult.ERROR:
		// 		this.snackbarCtrl.open(
		// 			this.translate.instant('payment_modal.error'),
		// 			null,
		// 			{
		// 				duration: 2000
		// 			}
		// 		);
		// 		break;
		// }
		this.loading = false;
	}

	isSelected(order: Order): boolean {
		return this.selectedOrders.has(order._id);
	}

	isSelectedByOthers(order: Order): boolean {
		return this.selectedOrdersByOthers.has(order._id);
	}

	backToMenu() {
		MenuPage.navigate(this.router);
	}
}
