import {Component, EventEmitter, Input, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {
	getPrice,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-toggle-button-group',
	templateUrl: './toggle-button-group.component.html',
	styleUrls: ['toggle-button-group.component.scss']
})
export class ToggleButtonGroupComponent {
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	ot = OrderType;

	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() preorderType: PreorderType;
	@Output() selection = new EventEmitter<ArticleOption>();

	constructor(public repository: RepositoryService) {}

	isSelected(article: Article) {
		return (
			this.selected.findIndex(
				selected =>
					selected.group === this.optionGroup._id &&
					selected.article._id === article._id
			) >= 0
		);
	}

	select(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.group = this.optionGroup._id;
		option.quantity = 1;
		this.selection.emit(option);
	}
}
